/**
 * Utilisée dans la popup de chargement des résultats
 */
@keyframes loading {
    0% {
        left: 0;
        width: 0;
    }

    50% {
        width: 100%;
        right: 0;
    }

    100% {
        width: 0;
        right: 0;
    }
}