.search__summary {

    &__wrapper {
        
        @include mobile {
            padding-bottom: 10px;
            display: flex;
            column-gap: 16px;
    
            > div {
                background-color: $color_light;
                border-radius: 7px;
                padding: 6px 70px 6px 12px;
        
                .search__summary__field__edit {
                    right: 6px;
                    top: 9px;
                }
            }
        }
    
        @include tablet {
            border-radius: 7px;
            padding: 16px;
            background-color: $color_light;
            display: grid;
            grid-auto-columns: minmax(0, 1fr);
            grid-auto-flow: column;
            column-gap: 40px;
            row-gap: 5px;
            max-width: 740px;
            height: 100%;
            grid-template-areas: 
                "a b d"            
                "a c d";
    
            #summary-stations {
                grid-area: a;
    
                &:after {
                    right: -20px;
                }

                &:before {
                    min-width: 20px;
                }
            }
    
            #summary-profile {
                grid-area: b;
            }
    
            #summary-profileType {
                grid-area: c;
            }
    
            #summary-frequency {
                grid-area: d;
    
                &:before {
                    left: -20px;
                }
            }
    
            #summary-stations:after, #summary-frequency:before {
                content: '';
                background-color: $color_secondary;
                display: inline-block;
                height: 100%;
                width: 2px;
                position: absolute;
            }
        }
    }

    &__field {
        display: flex;
        position: relative;
        padding-right: 20px;
        cursor: pointer;

        &:hover .search__summary__field__edit {
            filter: brightness(.5);
        }

        &.icon-location-msk:before, &#summary-frequency div span.icon-train:before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: $color_primary;
            margin-right: 5px;
        }

        // Stations
        &#summary-stations span {
            text-transform: lowercase;

            &:first-letter {
                text-transform: capitalize;
            }
        }

        &__value {
            position: relative;
            display: inline-flex !important;
            font-size: 16px;
            flex-wrap: wrap;
            max-width: 100%;

            &.blockValue {
                padding-right: unset;
                
                > div:not(.active) {
                    display: none;
                }

                > div {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    column-gap: 9px;
                    row-gap: 9px;
                }

                span {
                    display: flex;
                }
            }

            &.blockValue div span:not(.icon-train):before {
                content: '';
                width: 20px;
                height: 20px;
                position: relative;
                top: -2px;
            }

            // Profile
            .icon-minus:before, .icon-plus:before {
                display: flex !important;
                justify-content: center;
                align-items: center;
                color: $color_light;
                font-weight: 800;
                background-color: unset;
                top: 0px !important;
            }

            .icon-minus:before {
                content: '-' !important;
            }
            .icon-plus:before {
                content: '+' !important;
            }

            .icon-minus:after, .icon-plus:after {
                top: 3px !important;
            }
        }

        &__edit {
            @extend .ecoSearch__summary__field__edit;
        }
    }
}