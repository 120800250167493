.search__step {
    height: 100%;
    display: none;
    padding: 16px;
    flex-grow: 1;
    overflow: hidden;

    &.active {
        display: block;
    }

    &.error {

        .search__field__input {
            border: 2px solid $color_error !important;
            transition: none !important;
        }

        .search__step__content__error {
            display: block;
        }
    }

    &#step-1 .search__step__content:before {
        display: none;
    }

    &:nth-last-of-type(2) .search__step__content:after {
        display: none;
    }

    &:last-of-type .search__step__content:before, &:last-of-type .search__step__content:after {
        display: none;
    }

    &#step-5 .search__field__label:before {
        height: 25px;
    }

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        column-gap: 12px;
        margin-bottom: 20px;

        @include tablet {
            font-size: 32px;
            margin-bottom: 36px;
        }

        &:before {
            content: '';
            display: inline-block;
            background-color: $color_primary;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center;

            @include tablet {
                width: 60px;
                height: 60px;
                background-size: 25px;
            }
        }

        &.icon-time:before {
            background-position: 20px 10px;
        }

        &.icon-checklist:before {
            background-size: 20px;
        }

        &.icon-check:before {
            background-size: 30px;

            @include mobile {
                background-size: 24px;
            }
        }

        &.icon-station:before {
            background-size: 24px;

            @include tablet {
                background-size: 35px;
                background-position-x: 55%;
            }
        }

        &.icon-time:before {
            background-position: 19px 8px;
            background-size: 16px;

            @include tablet {
                background-position: 27px 14px;
                background-size: 22px;
            }
        }
    }

    &__content {
        position: relative;
        border-radius: 7px;
        background-color: $color_light;
        padding: 25px 16px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include laptop {
            max-width: 80vw;
            padding: 70px 16px 70px;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            top: 0px;
            display: block;
            height: 100%;
            width: 7vw;
            background-color: $color_light;
            border-radius: 7px;
        }

        &:before {
            left: -10vw;
        }

        &:after {
            right: -10vw;
        }

        &__wrapper {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            column-gap: 17px;
            row-gap: 17px;
        }

        &__error {
            display: none;
            color: $color_error;
            font-size: 14px;
            font-weight: 500;
            margin: 15px 0 -15px;

            @include tablet {
                margin: 15px 0 -25px;
                text-align: center;
            }
        }

        &--fields {

            .search__step__content__wrapper {
                column-gap: 13px;
                justify-content: center;
                flex-direction: column;
                flex-wrap: nowrap;

                @include tablet {
                    flex-direction: row;
                }
            }

            .search__field {
                flex-grow: 1;
            }
        }

        &__title {
            font-size: 16px;
            margin-bottom: 15px;
            text-align: center;

            @include tablet {
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 25px;
            }
        }

        .search__field__swapper {
            display: none;
            width: 48px;
            height: 48px;
            border-radius: 7px;
            background-color: rgba($color_eco_gray, 0.5);
            background-image: url('../medias/eco-search/icon_swap.svg');
            background-repeat: no-repeat;
            background-size: 23px 27px;
            background-position: center;
            align-self: flex-end;
            cursor: pointer;

            @include tablet {
                display: block;
            }
        }
    }

    &__navigation {
        margin-top: 30px;
        display: flex;
        column-gap: 36px;
        justify-content: center;

        @include tablet {
            margin-top: 45px;
            flex-basis: 100%;
        }
    }
}