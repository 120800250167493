// == Colors
$color_primary: #4c74b5;
$color_secondary: #FFDD4A;
$color_primary_light: #EDF1F7;

$color_light: #ffffff;
$color_dark: #000000;
$color_gray: #f7f5f5;
$color_gray_dark: #747678;
$color_text: #333333;

$color_success: #21c231;
$color_error: #ff0000;

$color_background: #F8F8F8;
$color_eco_gray: #EBEBEB;

//== Screen width
//With bootstrap
//$min-xs: $screen-xs-min;
//$max-xs: $screen-xs-max;
//$min-sm: $screen-sm-min;
//$max-sm: $screen-sm-max;
//$min-md: $screen-md-min;
//$max-md: $screen-md-max;
//$min-lg: $screen-lg-min;

//Without bootstrap
$min-xs: 480px;
$max-xs: 767px;
$min-sm: 768px;
$max-sm: 991px;
$min-md: 992px;
$max-md: 1199px;
$min-lg: 1200px;

// == Taille
//$header-height: 180px;

// == Container
$container: 1200px;
$container-medium: 970px;
