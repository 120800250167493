.header {
    padding: 10px 30px;
    border-bottom: 1px solid $color_eco_gray;
    background-color: $color_light;

    @include tablet {
        display: flex;
        align-items: center;
        height: 55px;
        padding: 0 25px;
        column-gap: 75px;
    }
    
    &__logo {
        display: block;
        background-image: url('../medias/logo_ter_bretagne_header.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 261px;
        height: 32px;
    }

    &__title {
        @extend .font-bretagne;
        font-size: 19px;
        text-transform: none;
        font-weight: 400;
        color: $color_text;

        @include tablet {
            font-size: 24px;
        }
    }
}
