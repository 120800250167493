.searchPage {

    .search__step#step-5 {
        @include mobile {
            padding-left: unset;
            padding-right: unset;
        }
    }

    .search__navigation {

        @include mobile {
            margin: unset;
            column-gap: 45px;

            &__step:after {
                width: 50px;
                right: -50px;
            }
        }
    }
}