.ecoSearch__result {
    background-color: unset !important;
    padding-top: 0 !important;
    max-width: 1125px !important;

    @include mobile {
        padding: unset !important;
    }

    .icon-logo {
        background-image: url('../medias/logo_small.png');
        display: inline-block;
        width: 139px;
        height: 26px;
        vertical-align: middle;
    }

    .icon-carbon {
        mask-image: url('../medias/eco-search/icon_carbon.svg');
        background-color: rgba($color_gray_dark, 0.5);
        display: inline-block;
        width: 80px;
        height: 48px;
        vertical-align: middle;
        margin-top: -30px;
    }

    &__block {
        padding: 17px;
        background-color: $color_light;
        border-radius: 7px;
        white-space: nowrap;

        @include tablet {
            padding: 17px 25px;
        }

        &.active {
            background-color: $color_primary;
            color: $color_light;

            .btn--info {
                @extend .btn--info--light;
            }
        }

        &--promo {   
            .icon-car {
                margin-top: 5px;

                &:before {
                    background-color: $color_text;
                }
                &:after {
                    border-color: $color_text;
                }
            }
        }

        &--carbon {            
            background-image: url('../medias/eco-search/result_carbon_bg.svg');
            background-repeat: no-repeat;
            background-position: bottom right;

            &.active {
                .icon-carbon {
                    background-color: $color_light;
                }
            }
        }

        &--summary {
            padding: 20px 15px 30px;

            @include tablet {
                padding: 20px 30px 30px;
            }

            > div {
                display: flex;
                justify-content: space-between;
                column-gap: 55px;
                margin-bottom: 25px;

                span:nth-child(2) {
                    position: relative;
                    white-space: normal;
                    
                    &:before {
                        content: '';
                        background-color: $color_secondary;
                        margin: 0;
                        display: block;
                        width: 2px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: -30px;
                    }

                    .ecoSearch__result__block__value {
                        white-space: normal;
                        line-height: 20px;
                        margin-top: 13px;
                    }

                    .ecoSearch__result__block__info {
                        display: inline-block;
                        margin-left: 5px;
                    }
                }
            }

            .btn {
                min-width: 180px;
                max-width: 180px;
                padding: 7px;
                color: $color_text;
            }

            .ecoSearch__result__block__value {
                display: block;
            }
        }

        &--train, &--car {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;

            @include tablet {
                padding: 23px 27px;
            }

            > .ecoSearch__result__block {
                align-self: normal;
            }

            .ecoSearch__result__block__value {

                @include mobile {
                    font-size: 30px;
                }
            }
        }

        &--train {
            background-color: lighten($color_primary, 45%);
        }

        &__value {
            font-size: 35px;
            white-space: nowrap;
        }

        &__info {
            font-size: 16px;
            color: $color_gray_dark;
        }

        &__title {
            font-size: 25px;

            &:before, &:after {
                width: 49px !important;
                height: 49px !important;
                mask-size: 26px 26px !important;
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        column-gap: 38px;
        margin-bottom: 35px;

        @include laptop {
            flex-direction: row;
            justify-content: space-between;
        }

        > .ecoSearch__result__block {
            flex-basis: 30%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        column-gap: 36px;
        row-gap: 36px;

        @include laptop {
            flex-direction: row;
        }

        > .ecoSearch__result__block {
            flex-grow: 1;

            &--train > .ecoSearch__result__block {
                background-color: $color_primary;
                color: $color_light;

                .btn:hover {
                    background-color: $color_secondary;
                    border-color: $color_secondary;
                }
            }

            &--car > .ecoSearch__result__block {
                background-color: $color_gray;
            }
        }
    }

    &__compare {
        margin-top: 10px;
        text-align: center;
        white-space: normal;

        > div {
            display: flex;
            justify-content: center;
            column-gap: 27px;
            margin-top: 15px;
        }

        &__block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid $color_primary;
            border-radius: 7px;
            width: 145px;
            height: 125px;
            font-size: 12px;
            padding: 17px 17px 12px;
    
            &:before {
                content: '';
                background-color: $color_primary;
                width: 20px;
                height: 20px;
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
                margin-bottom: 3px;
            }
    
            &.icon-water:before {
                mask-image: url('../medias/eco-search/icon_water.svg');
            }
    
            &.icon-stream:before {
                mask-image: url('../medias/eco-search/icon_stream.svg');
            }
    
            
        }

        &__value {
            font-size: 23px;
            font-weight: 500;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-gap: 35px;
        row-gap: 20px;
        margin-top: 30px;

        @include tablet {
            flex-direction: row;
        }

        .btn--prev {
            min-width: 250px;
            max-width: 250px;
            padding: 6px !important;
            border-width: 1px;

            &:before {
                background-color: $color_light;
            }

            &:hover {
                &:before {
                    background-color: $color_primary;
                }
            }
        }

        .btn--linePrimary {
            @extend .popupLink;

            padding: 7px !important;
            min-width: 300px;
            max-width: 300px;
            text-decoration: none !important;

            &:before {
                transition: .3s;
            }

            &:hover {
                color: $color_text;
            }
        }
    }
}