@media print {
	.result {
		&__header {
			border-bottom: 2px solid $color_primary;
			font-size: 16px;
		}

		&__tooltip {
			border-bottom: 2px solid $color_gray;
		}

		&__container {
			&__header {
				display: none;
			}
			&__content {
				position: relative;
				display: block;
			}

			&__success {
				display: none;
			}
		}

		&__card {
			min-height: 360px;
			border: 2px solid $color_primary;
			break-inside: avoid;
			page-break-inside: avoid;
			position: relative;
			margin-bottom: 60px;

			&--promotion {
				border: 2px solid $color_secondary;
			}

			&--sheets {
				display: none;
			}

			&__price {
				&__total {
					margin-bottom: 10px;
				}

				&__single {
					margin: -10px 0 10px;
				}
			}

			&__button {
				display: none !important;
			}
		}

		&__links {
			display: none;
		}

		&__ad {
			display: none;
		}
	}
}
