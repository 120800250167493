.resultBlock {
    position: relative;
    padding: 11px;
    border-radius: 7px;
    user-select: none;

    &:first-of-type:not(.resultBlock__options > div):not(.promo) {

        .resultBlock__wrapper {
            border: 1px solid $color_primary;
        }

        .resultBlock__title {
            color: $color_light;
            padding: 7px 16px 16px;
            background-color: $color_primary;
        }

        .resultBlock__price__before {
            margin-top: 7px;
        }

        .resultBlock__link:not(:hover) {
            background-color: $color_primary;
            border-color: $color_primary;
        }
    }

    &.promo {
        .resultBlock__wrapper {
            border: 1px solid $color_secondary;
        }

        .resultBlock__title {
            background-color: $color_secondary;
        }

        .resultBlock__recommended {
            color: $color_gray_dark;
        }
    }

    &.options-visible {
        opacity: 1;
        background-color: lighten($color_primary, 45%);
        
        .resultBlock__options {
            max-height: 1800px;
            transition: 1.5s;
        }
    }

    &.informations-visible {
        opacity: 1;

        .resultBlock__information {
            max-height: 300px;
        }

        .resultBlock__information__toggle {
            border: 1px dashed $color_primary;
            color: $color_primary;

            &:after {
                transform: rotate(270deg);
                background-color: $color_primary;
            }
        }
    }

    &__wrapper {
        min-height: 300px;
        width: 250px;
        border-radius: 7px;
        border: 1px solid $color_gray_dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
        box-shadow: 0px 0px 10px rgba(116, 118, 120, 0.11);
        background-color: $color_light;
        padding-bottom: 15px;

        @include tablet {
            width: 200px;
        }
    }

    &__recommended {
        position: absolute;
        bottom: -15px;
        display: block;
        background-color: $color_secondary;
        color: $color_text;
        padding: 3px 10px;
        border-radius: 7px;
    }

    &__title {
        position: relative;
        background-color: lighten($color_primary, 31%);
        text-align: center;
        font-size: 15px;
        min-height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 16px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    &__price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80px;

        &__before, &__frequency {
            color: $color_gray_dark;
            font-size: 15px;
        }

        &__value {
            font-size: 35px;
            line-height: 35px;
        }
    }

    &__link {
        height: 40px;
        padding: 5px 35px;
        background-color: $color_gray_dark;
        border-color: $color_gray_dark;
    }

    &__informationSmall {
        color: $color_gray_dark;
        font-size: 15px;
        text-align: center;
        padding: 0 7px;
    }

    &__information {
        max-height: 0;
        overflow: hidden;
        padding: 0 16px;
        transition: .5s;

        p:first-child {
            margin-top: unset;
        }

        p:last-child {
            margin-bottom: unset;
        }

        ul, ol {
            padding-left: 16px;
        }
        
        &__toggle {
            padding: 0 5px;
            border: 1px dashed transparent;
            cursor: pointer;
            transition: .3s;
            font-size: 12px;

            &:after {
                content: '';
                display: inline-block;
                width: 5px;
                height: 9px;
                mask-image: url('../medias/icon_arrow_button.svg');
                mask-size: contain;
                mask-repeat: no-repeat;
                background-color: $color_text;
                margin-left: 9px;
                transition: .3s;
                transform: rotate(180deg);
            }

            &:hover {
                &:after {
                    transform: rotate(270deg);
                }
            }
        }
    }

    &__options {
        max-height: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        row-gap: 28px;
        transition: 0.7s;

        &__toggle {
            padding: 8px 0 11px;
            margin-bottom: -28px;
            text-align: center;
            text-decoration: underline;
            font-size: 15px;
            cursor: pointer;

            &:hover {
                color: $color_primary;
            }
        }

        .resultBlock {
            padding: unset;

            &__wrapper {
                min-height: 230px;
            }
        }
    }
}