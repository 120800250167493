.search {
    color: $color_text;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &:not(.active) {
        .search__navigation {
            display: none;
        }
    }

    &.active {
        .search__home {
            display: none;
        }
    }
}