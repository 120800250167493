html,
body {
	@extend .font-avenir, .font-book;
	font-size: 14px;
	color: $color_dark;
	line-height: 1.5;
}

h1,
h2,
h3 {
	margin: 0;
	padding: 0;
}

h1 {
	font-size: 26px;
	text-transform: uppercase;
	@include desktop {
		font-size: 40px;
	}
}

h2 {
	@extend .font-book;
	font-size: 14px;
	@include tablet {
		font-size: 16px;
	}
}

a {
	@extend .font-book;
	color: initial;
	text-decoration: underline;
}

.underline {
	text-decoration: underline;
}

.wysiwyg {
	
	a {
		color: $color_primary;
	}
}