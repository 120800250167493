.footer {
    background-color: $color_light;
    padding: 15px 50px;
    border-top: 1px solid $color_eco_gray;
    background-color: $color_light;
    margin-top: auto;

    &__logo {
        margin-left: auto;
        display: block;
        background-image: url('../medias/logo_ter_bretagne_header.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 261px;
        height: 32px;
        margin-bottom: 15px;
    }

    &__socials {
        display: flex;
        justify-content: center;
        column-gap: 12px;
        border: 1px solid $color_gray_dark;
        border-left: unset;
        border-right: unset;

        &__item {
            width: 25px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                background-color: $color_primary;
                mask-position: center;
                display: block;
                height: 20px;
                width: 20px;
                transition: .2s;
            }

            &:hover:before {
                background-color: rgba($color_primary, 0.8);
            }
        }
    }

    &__links {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 10px;

        @include tablet {
            flex-direction: row;
        }

        &__item {
            text-decoration: unset;
            display: inline-flex;
            align-items: center;

            &:hover {
                text-decoration: underline;
            }

            @include tablet {
                &:not(:last-of-type):after {
                    content: '';
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    background-color: $color_text;
                    border-radius: 50%;
                    margin: 0 15px;
                }
            }
        }
    }
}