.btn {
	display: inline-block;
	text-decoration: none;
	box-shadow: none;
	background-color: transparent;
	cursor: pointer;
	border: 0;

	&--linePrimary {
		border: 2px solid $color_primary;
		border-radius: 5px;
		color: $color_primary;
		min-width: 250px;
		text-align: center;
		padding: 17px;
		transition: all 0.3s ease;
		font-size: 16px;
		font-weight: 500;

		@include tablet {
			min-width: 300px;
		}

		&:hover,
		&:focus {
			border-color: lighten($color_primary, 30%);
            background-color: lighten($color_primary, 30%);
            color: $color_text;
		}

		&:disabled {
			border-color: $color_gray_dark;
			color: $color_gray_dark;
			pointer-events: none;
		}

		&--small {
			
			&:hover {
				background-color: lighten($color_primary, 30%);
				border-color: lighten($color_primary, 30%);
				color: $color_text;

				&.btn--prev:before, &.btn--next:after {
					background-color: $color_text;
				}
			}
		}

		&.btn--prev:disabled:before, &.btn--next:disabled:after {
			background-color: $color_gray_dark;
		}
	}

	&--small {
		min-width: 140px;
		max-width: 140px;
		max-height: 40px;
		border-radius: 6px;
		padding: 9px;
		border-width: 1px;
		font-size: 16px;
	}

	&--prev:before, &--next:after {
		content: '';
		display: inline-block;
		width: 5px;
		height: 8px;
		mask-image: url('../medias/icon_arrow_button.svg');
		mask-size: contain;
		background-color: $color_primary;
		position: relative;
		bottom: 1px;
		transition: all 0.3s ease;
	}

	&--prev:before {
		margin-right: 10px;
	}

	&--next:after {
		transform: rotate(180deg);
		margin-left: 10px;
	}

	&--primary {
		background-color: $color_primary;
		border: 2px solid $color_primary;
		border-radius: 5px;
		color: $color_light;
		text-align: center;
		padding: 12px;
		transition: all 0.3s ease;
		font-size: 16px;
		font-weight: 500;

		&:hover,
		&:focus {
			border-color: lighten($color_primary, 30%);
            background-color: lighten($color_primary, 30%);
            color: $color_text;
		}
	}

	&--primary-light {
		@extend .btn--primary;
		background-color: $color_light;
		border: 2px solid $color_light;
		color: $color_primary;

		&:hover,
		&:focus {
			background-color: $color_primary;
			color: $color_light;
		}
	}

	&--secondary-light {
		@extend .btn--primary;
		background-color: $color_light;
		border: 2px solid $color_light;
		color: $color_dark;

		&:hover,
		&:focus {
			background-color: $color_secondary;
		}
	}

	&--gray {
		background-color: $color_gray;
		border-radius: 5px;
		color: $color_primary;
		text-align: center;
		padding: 14px;
		transition: all 0.3s ease;
		font-size: 15px;
		font-weight: 500;

		&:hover,
		&:focus,
		&.active {
			background-color: $color_primary;
			color: $color_light;
		}
	}

	&--link {
		color: $color_primary;
		text-decoration: underline;
		font-size: 16px;
		font-weight: 500;
		transition: all 0.3s ease;

		&:hover,
		&:focus {
			color: darken($color_primary, 10%);
			text-decoration: none;
		}
	}

	&--stepIndicator {
		color: $color_primary;
		border: 1px solid $color_primary;
		border-radius: 5px;
		padding: 2px 15px;
		font-weight: 900;

		@include laptop {
			font-size: 20px;
		}

		&--multiple {
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;
			column-gap: 5px;

			strong,
			span {
				font-weight: 900;
				display: block;
			}

			span {
				width: 14px;
				height: 9px;
				//background: transparent url('../medias/icon_arrow.svg') center center no-repeat;
				background-color: $color_primary;
				mask: url("../medias/icon_arrow.svg") center center no-repeat;
				mask-size: contain;
			}
		}
	}

	&--info {
		width: 13px;
		height: 13px;
		background-color: rgba($color_gray_dark, 0.5);
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		vertical-align: super;
		
		&:before {
			content: 'i';
			display: block;
			font-size: 11px;
			font-weight: 800;
			color: $color_light;
		}

		&--light {
			background-color: rgba($color_light, 0.5);

			&:before {
				color: $color_primary;
			}
		}
	}
}
