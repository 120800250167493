.search__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    margin: auto;
    padding: 40px 0 25px;

    @include tablet {
        padding: 50px 0 45px;
        column-gap: 52px;
    }

    &__step {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: $color_eco_gray;
        border-radius: 50%;
        font-size: 15px;
        text-decoration: none;
        pointer-events: none;
        margin: 0 2px;
        user-select: none;
        // transition: .2s;

        @include tablet {
            font-size: 20px;
            width: 34px;
            height: 34px;
            margin: 0 4px;
        }

        &:last-of-type:before {
            content: '';
            mask-image: url('../medias/eco-search/icon_check.svg');
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
            display: block;
            width: 16px;
            height: 13px;
            background-color: $color_text;

            @include tablet {
                width: 19px;
            }
        }

        &:not(:last-of-type):after {
            content: '';
            position: absolute;
            top: 14px;
            height: 2px;
            background-color: $color_eco_gray;
            width: 20px;
            right: -20px;
    
            @include tablet {
                top: 20px;
                width: 60px;
                right: -60px;
            }
        }

        &:not(.active):after {
            top: 11px;

            @include tablet {
                top: 16px;
            }
        }

        // &.completed:not(.active):hover {
        //     width: 34px;
        //     height: 34px;
        // }

        &.completed {
            pointer-events: all;
            cursor: pointer;
            background-color: lighten($color_primary, 30%);

            &:after {
                background-color: lighten($color_primary, 30%);
            }

            & + .search__navigation__step:not(.active):not(:last-of-type) {
                cursor: pointer;
                pointer-events: all;
            }
        }

        &.active {
            background-color: $color_primary;
            width: 30px;
            height: 30px;
            color: $color_light;
            margin: 0;

            @include tablet {
                width: 42px;
                height: 42px;
            }

            &:last-of-type:before {
                background-color: $color_light;
            }
        }        
    }
}