@font-face {
	font-family: "Avenir";
	src: url("../fonts/avenir/Avenir-Book.woff2");
	font-weight: 300;
}

@font-face {
	font-family: "Avenir";
	src: url("../fonts/avenir/Avenir-Medium.woff2");
	font-weight: 500;
}

@font-face {
	font-family: "Avenir";
	src: url("../fonts/avenir/Avenir-Black.woff2");
	font-weight: 900;
}

@font-face {
	font-family: "Region Bretagne New";
	src: url("../fonts/ter/RegionBretagne-Thin.otf");
	font-weight: 300;
}

@font-face {
	font-family: "Region Bretagne New";
	src: url("../fonts/ter/RegionBretagne-Reg.otf");
	font-weight: 400;
}

@font-face {
	font-family: "Region Bretagne New";
	src: url("../fonts/ter/RegionBretagne-Medium.otf");
	font-weight: 500;
}

@font-face {
	font-family: "Region Bretagne New";
	src: url("../fonts/ter/RegionBretagne-Bold.otf");
	font-weight: 700;
}

.font {
	&-avenir {
		font-family: "Avenir", sans-serif;
	}
	&-bretagne {
		font-family: "Region Bretagne New", sans-serif;
	}
	&-book {
		font-weight: 300;
	}
	&-medium {
		font-weight: 500;
	}
	&-black {
		font-weight: 900;
	}
}
