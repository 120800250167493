.search__result {
    background-color: unset;
    max-width: 1234px;

    @include mobile {
        padding: unset;
    }

    &__header {
        margin-bottom: 30px;

        @include laptop {
            display: flex;
            column-gap: 40px;
        }
    }

    &__infos {
        position: absolute;
        right: 30px;
        top: -61px;

        &.active.open {
            .search__result__infos__content {
                display: flex;
            }
        }

        @include laptop {
            position: unset;
            
            &.active {
                .search__result__infos__content {
                    display: flex;
                }
            }
        }

        &__content {
            display: none;
            position: relative;

            background-color: $color_primary_light;
            padding: 11px 11px 11px 40px;
            align-items: center;
            font-size: 14px;
            border-radius: 7px;
            max-width: min(80vw, 350px);
            z-index: 100;
            pointer-events: none;

            @include laptop {
                padding: 11px 60px;
                font-size: 16px;
                max-width: 450px;

                &:before {
                    left: 20px;
                }

                &:after {
                    display: none;
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: 10px;
                display: block;
                width: 20px;
                height: 20px;
                mask-image: url('../medias/icon_infos.svg');
                mask-repeat: no-repeat;
                background-color: $color_primary;
            }

            &:after {
                content: '';
                width: 12px;
                height: 12px;
                mask-image: url('../medias/icon_cross.svg');
                mask-size: contain;
                background-color: $color_primary;
                position: absolute;
                top: 11px;
                right: 11px;
            }
        }

        &__toggle {
            
            @include laptop {
                display: none;
            }

            position: absolute;
            right: 0;
            display: block;
            width: 42px;
            height: 42px;
            background-image: url('../medias/icon_infos.svg');
            background-size: 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-color: $color_primary_light;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    &__items {
        display: flex;
        align-items: flex-start;
        
        @include laptop {
            column-gap: 12px;

            > *:not(:first-child) {
                opacity: 0.5;
                transition: .3s;
    
                &:hover {
                    opacity: 1;
                }
            }
    
            > *:first-child {
                margin-right: 29px;
    
                &:after {
                    content: '';
                    position: absolute;
                    right: -21.5px;
                    top: 57px;
                    display: block;
                    height: 185px;
                    width: 2px;
                    background-color: $color_secondary;
                }
            }
        }
    }

    &__actions {

        @include laptop {
            position: absolute;
            top: -115px;
            right: 42px;
        }
    }
}