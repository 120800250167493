.search__form {
    flex-grow: 1;
    letter-spacing: 0.3px;
    font-weight: 350;
    display: flex;
    flex-direction: column;

    .iconCircled {
        position: relative;
        display: inline-flex;
        align-items: center;
        column-gap: 9px;

        &:before {
            content: '';
            display: inline-block;
            width: 34px;
            height: 34px;
            background-color: $color_primary;
            mask-repeat: no-repeat;
            mask-size: 16px 16px;
            mask-position: center;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 34px;
            height: 34px;
            border: 2px solid $color_primary;
            border-radius: 50%;
        }

        &--full {

            &:before {
                background-color: $color_light;
                z-index: 1;
            }

            &:after {
                background-color: $color_primary;
            }
        }

        &--small {
            
            &:before {
                mask-size: 8px 8px;
            }

            &:before, &:after {
                width: 20px;
                height: 20px;
            }
        }
    }

    .popupLink {
        display: block;
        font-size: 14px;
        color: $color_primary;
        padding: 5px 5px 5px 0;
        cursor: pointer;
        text-decoration: underline;

        @include tablet {
            font-size: 16px;
        }

        &:before {
            content: '+';
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 21px;
            height: 21px;
            color: $color_light;
            background-color: $color_primary;
            font-size: 17px;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
}