.ecoSearch__summary {

    &__wrapper {
        @include tablet {
            display: flex;
            justify-content: center;
            column-gap: 90px;
            padding: 0 25px 30px;
        }
    }

    &__block {

        &:nth-child(2) {
            position: relative;
            
            &:before {
                content: '';
                background-color: $color_secondary;
                display: block;
                height: 2px;
                width: 65%;
                position: relative;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                margin: 30px 0 20px;

                @include tablet {
                    position: absolute;
                    left: -45px;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: 0;
                    width: 2px;
                    height: 80%;
                }
            }
        }
        
        &__title {
            font-size: 20px;
            font-weight: 350;
            color: $color_primary;

            @include tablet {
                font-size: 25px;
            }
        }
    }

    &__field {

        &__label {
            position: relative;
            display: block;
            font-size: 12px;
            color: $color_gray_dark;
            padding-right: 70px;
            margin-top: 15px;
        }

        &__edit {
            position: absolute;
            right: 0;
            top: 3px;
            display: block;
            width: 15px;
            height: 15px;
            background-image: url('../medias/eco-search/icon_edit.svg');
            cursor: pointer;
            transition: .1s;

            &:hover {
                filter: brightness(0.5);
            }
        }

        &__value {
            display: block;
            font-size: 16px;
            padding-right: 70px;

            &.blockValue {
                margin-top: 9px;
                padding-right: unset;
                
                > div:not(.active) {
                    display: none;
                }

                > div {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    column-gap: 9px;
                    row-gap: 9px;
                }
            }
        }
    }

    &__actions {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 15px;
        margin: 25px 0 0;
  
        @include tablet {
            max-width: 1090px;
            min-width: 740px;
            width: 100%;
            margin: auto;
        }
        
        &__edit {
            font-size: 16px;
            color: $color_primary;
            padding: 5px;
            cursor: pointer;

            @include tablet {
                position: absolute;
                left: 25px;
            }
        }

        &__submit {
            font-weight: 500;
            text-transform: none;
            font-size: 18px !important;
            letter-spacing: .5px;
            padding: 10px 50px;
            width: 100%;

            @include tablet {
                max-width: 285px;
            }
        }
    }
}