.search__home {
    padding: 45px 30px;
    background-color: $color_light;
    flex-grow: 1;
    
    @include tablet {
        padding: 0 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 50px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 500px;
    }

    &__title, &__subtitle {
        @extend .font-bretagne;
        font-size: 27px;
        font-weight: 500;

        @include tablet {
            font-size: 32px;
        }
        
        b {
            color: $color_primary;
            font-weight: 850;
        }

        p {
            margin: unset;
        }

        img {
            width: 180px;

            @include tablet {
                width: 226px;
            }
        }
    }

    &__subtitle {
        font-size: 16px;
        padding-top: 15px;
        font-weight: 400;
    }

    &__duration {
        display: flex;
        align-items: center;
        margin: 25px 0 40px;
        padding: 6px 16px;
        font-size: 16px;
        background-color: rgba($color_gray_dark, 0.1);
        border-radius: 31px;

        &:before {
            content: '';
            display: inline-block;
            width: 21px;
            height: 21px;
            background-image: url('../medias/icon_duration.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 9px;
        }
    }

    &__action {
        width: 200px;
        font-weight: 600 !important;
    }

    &__image {
        display: block;
        max-width: 470px;
        margin: 50px auto 0;

        @include tablet {
            margin: unset;
        }
    }
}