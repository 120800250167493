.select2 {
        
	&-selection {
		padding: 8px 20px 8px 52px !important;

		&--address {
			padding-top: 17px !important;
		}

		&:not(.select2-selection--address) .select2-selection__rendered {
			text-transform: lowercase;

			&:first-letter {
				text-transform: capitalize;
			}
		}

		&__rendered {
			top: unset !important;	
		}
	}

	&-search {
		padding: unset;
	
		&__field {
			@extend .font-avenir;
			font-weight: 350;
			letter-spacing: .5px;
			font-size: 16px;
			position: absolute;
			top: -40px;
			left: 46px;
			width: 80% !important;
			background: transparent;
			border: unset !important;
			appearance: none;
			text-transform: capitalize;
		}
	}

	&-dropdown {

		input {
			color: $color_text;
		}

		&--address input {
			top: -31px;
		}

		&:not(.select2-dropdown--address) {
			.select2-results__option {
				text-transform: lowercase;
	
				&:first-letter {
					text-transform: capitalize;
				}
			}
		}
	}
}