//== Breakpoints
@mixin desktop {
	@media (min-width: $min-lg) {
		@content;
	}
}

@mixin laptop {
	@media (min-width: $min-md) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: $min-sm) {
		@content;
	}
}

@mixin tablet-only {
	@media (min-width: $min-sm) and (max-width: $max-sm) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: $max-xs) {
		@content;
	}
}

// IE 10+
@mixin ie10-hack {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	}
}

/**
 * Icônes
 */
@mixin setIcons($folder, $default_type, $icon_names)
{
	@each $icon in $icon_names 
	{
		.icon-#{$icon}:before {
			#{$default_type}-image: url('../medias/#{$folder}/icon_#{$icon}.svg');
		}

		.icon-#{$icon}-bkg:before {
			background-image: url('../medias/#{$folder}/icon_#{$icon}.svg');
		}

		.icon-#{$icon}-msk:before {
			mask-image: url('../medias/#{$folder}/icon_#{$icon}.svg');
		}
	}
}
@include setIcons('eco-search',    'background', ('checklist', 'profile', 'station', 'time', 'travel', 'location', 'check'));
@include setIcons('transport',     'mask',       ('car', 'urban', 'walk', 'person', 'train'));
@include setIcons('profiles',      'mask',       ('less26', 'more26'));
@include setIcons('frequencies',   'mask',       ('weekly', 'monthly', 'yearly'));
@include setIcons('socials',       'mask',       ('facebook', 'instagram', 'twitter', 'website', 'youtube'));
@include setIcons('profile_types', 'mask',       ('alone', 'group'));