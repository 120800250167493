form {

    .search__field {
        position: relative;
        display: flex;
        flex-direction: column;

        &--radio {
            width: 145px;
            height: 110px;

            @include tablet {
                width: 160px;
                height: 130px;
            }
            
            .search__field__input {
                display: none;

                &:checked + .search__field__label {
                    background-color: $color_primary;
                    color: $color_light;

                    .search__field__label__subtitle {
                        color: $color_light;
                    }

                    &:before {
                        background-color: $color_light;
                    }
                }
            }
            
            .search__field__label {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                height: 100%;
                row-gap: 3px;
                padding: 0 15px;
                background-color: $color_gray;
                border-radius: 7px;
                transition: all .3s, color 0s;
                user-select: none;
                cursor: pointer;
                font-size: 14px;

                @include tablet {
                    font-size: 16px;
                }

                &:before {
                    content: '';
                    display: block;
                    height: 35px;
                    width: 55px;
                    background-color: $color_primary;
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    margin-bottom: 5px;
                }

                &:hover {
                    background-color: lighten($color_primary, 30%);
                }

                &.icon-car:before {
                    height: 30px;
                }

                &__subtitle {
                    display: block;
                    color: $color_gray_dark;
                    font-size: 12px;
                }
            }
        }

        &--text {
            justify-content: flex-end;

            @include tablet {
                max-width: 240px;
            }

            .search__field__label {
                display: block;
                font-size: 16px;
                font-weight: 350;
                margin-bottom: 10px;
    
                @include tablet {
                    margin-bottom: 14px;
                }
            }

            .search__field__input__wrapper {
                position: relative;
                height: 48px;
                width: 100%;
    
                .search__field__input {
                    font-size: 16px;
                    border-radius: 7px;
                    background-color: rgba($color_eco_gray, 0.5);
                    border: unset;
                    width: 100%;
                    height: 100%;
                    padding: 17px 15px 15px;
                    letter-spacing: 0.5px;
                }
            }
        }

        &--address {
            justify-content: flex-end;

            @include tablet {
                max-width: 425px;
            }

            .search__field__label {
                display: block;
                font-size: 16px;
                font-weight: 350;
                margin-bottom: 10px;
    
                @include tablet {
                    margin-bottom: 21px;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
    
            // Champs texte animés
            .search__field__input__wrapper, .select2 {
                position: relative;
                height: 48px;
                width: 100% !important;

                .select2-selection {
                    height: 100%;
                    border: unset;

                    &__arrow {
                        display: none;
                    }
                }
    
                .search__field__input, .select2-selection {
                    border-radius: 7px;
                    background-color: rgba($color_eco_gray, 0.5);
                    border: 1px dashed transparent;
                    border-bottom: unset;
                    width: 100%;
                    height: 100%;
                    padding: 24px 20px 8px 52px;
                    letter-spacing: 0.5px;
                    transition: .5s;
    
                    &:disabled {
                        padding: 13px 20px 15px 52px;
                        font-size: 16px;
                        color: $color_text;
                    }
    
                    &:not(select):focus, &:not(select):valid {
                        border-color: $color_primary;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
    
                        & + .search__field__placeholder {
                            font-weight: 300;
                            font-size: 13px;
                            top: 3px;
                        }
    
                        & ~ .search__field__picto:after {
                            transform: scaleX(1);
                        }
                    }
                }

                .select2-container--open {

                    .select2-selection__rendered {
                        display: none;
                    }
                }

                .select2-container--open, .select2-container--filled {

                    .select2-selection {
                        border-color: $color_primary;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    & + .search__field__placeholder {
                        font-weight: 300;
                        font-size: 13px;
                        top: 3px;
                    }

                    & ~ .search__field__picto:after {
                        transform: scaleX(1);
                    }
                }

                .select2-selection__rendered {
                    font-size: 16px;
                    top: -9px;
                    position: relative;
                    left: -10px;
                    letter-spacing: 0.5px;
                    font-weight: 350;
                }
        
                .search__field__placeholder {
                    position: absolute;
                    top: 13px;
                    left: 52px;
                    color: $color_gray_dark;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.5 !important;
                    transition: .5s;
                    user-select: none;
                    pointer-events: none;
                }
                
                .search__field__picto {
    
                    &:before {
                        content: '';
                        position: absolute;
                        left: 18px;
                        top: 12px;
                        display: block;
                        width: 17px;
                        height: 23px;
                        background-image: url(../medias/icon_location.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
    
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        display: block;
                        height: 2px;
                        width: 100%;
                        background-color: $color_primary;
                        transform: scaleX(0);
                        transition: .4s;
                    }
                }
            }
        }

        &--select {

            .search__field__input__wrapper {
                position: relative;

                &:after {
                    content: '';
                    mask-image: url('../medias/icon_arrow_button.svg');
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    background-color: $color_primary;
                    display: block;
                    width: 8px;
                    height: 12px;
                    transform: rotate(-90deg);
                    position: absolute;
                    right: 20px;
                    top: 17px;
                }
            }

            .search__field__input {
                background-color: rgba($color_eco_gray, 0.5);
                appearance: none;
                font-size: 16px;
                padding: 17px 15px 15px;
                width: 100%;
                border: none;
                border-radius: 7px;
                cursor: pointer;                
            }

            .search__field__label {
                display: block;
                font-size: 16px;
                font-weight: 350;
                margin-bottom: 10px;
    
                @include tablet {
                    margin-bottom: 14px;
                }
            }
        }
    }
}
