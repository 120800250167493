*,
*:before,
*:after {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

*::selection {
	background: $color_primary;
	color: $color_secondary;
}

body {
	overflow-x: hidden;
	background-color: $color_background;
}

main {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

img {
	max-width: 100%;
	height: auto;
}
