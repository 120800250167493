.swiper {

    @include tablet {
        overflow: hidden;

        // &-mobile-only {
        //    .swiper-wrapper {
        //         transform: unset !important;
        //    }
        //    .swiper-slide {
        //         width: unset !important;
        //         margin-right: unset !important;
        //    }
        // }
    }

    &-wrapper {
        display: flex;
    }

    &-slide {
        flex-shrink: 0;
    }

    &-navigation {
        display: flex;
        pointer-events: none;
        
        .swiper-button-prev, .swiper-button-next {
            width: 29px;
            height: 29px;

            mask-image: url('../images/arrow_circle.svg');
            mask-size: contain;
            mask-repeat: no-repeat;

            pointer-events: all;
            cursor: pointer;
        }

        .swiper-button-prev {
            transform: rotate(90deg);
        }

        .swiper-button-next {
            transform: rotate(-90deg);
        }
    }

    &-pagination {
        display: flex;
        column-gap: 8px;
        
        .swiper-pagination-bullet {
            display: block;
            width: 7px;
            height: 7px;
            opacity: .15;
            background-color: $color_light;
            border-radius: 50%;

            &-active {
                opacity: 1;
            }
        }
    }
}