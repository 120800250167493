.formPopup {
    background-color: $color_light;
    border: none;
    border-radius: 12px;
    padding: 0;
    width: 90vw;
    max-width: 900px;
    overflow: hidden;

    &--small {
        max-width: 650px;
    }

    &::backdrop {
        background-color: rgba(#000, 0.5);
    }

    &__title {
        padding: 15px;
        font-size: 16px;
        background-color: rgba($color_primary, 0.1);

        @include tablet {
            padding: 20px 50px;
            font-size: 25px;
        }

        &__close {
            mask-image: url('../medias/icon_cross.svg');
            mask-size: contain;
            background-color: $color_primary;
            position: absolute;
            right: 20px;
            top: 20px;
            display: block;
            width: 18px;
            height: 18px;
            cursor: pointer;

            @include tablet {
                right: 30px;
                top: 30px;
                width: 21px;
                height: 21px;
            }
        }
    }

    &__content
    {
        padding: 40px 20px;
        overflow: scroll;
        max-height: 80vh;

        @include tablet {
            overflow: auto;
        }

        @include tablet {
            padding: 40px 50px;
        }
        
        &__text {
            picture img {
                margin: auto;
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;
            column-gap: 15px;
            row-gap: 15px;
            margin-top: 35px;

            @include mobile {
                flex-direction: column;
            }

            .btn {
                padding: 10px 35px;
            }
        }
    }
}

.search__loader {

    .formPopup__content__text {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include tablet {
            margin: 60px auto;
        }
    }

    &__title {
        color: $color_primary;
        margin-bottom: 10px;
        font-size: 25px;
    }

    &__subtitle {
        margin-bottom: 25px;
        font-size: 16px;
    }

    &__loader {
        position: relative;
        mask-image: url('../medias/loader.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        width: 100%;
        max-width: 445px;
        aspect-ratio: 13/1;
        background-color: lighten($color_primary, 20%);

        &:before {
            content: '';
            background-color: $color_primary;
            position: absolute;
            top: 0;
            height: 100%;
            animation: loading 3s linear infinite;
        }
    }
}

.ecoSearch__carOptions {
    
    .formPopup__content__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        column-gap: 30px;
        row-gap: 25px;

        @include tablet {
            flex-direction: row;
        }

        .ecoSearch__field {
            flex-grow: 1;
            text-align: center;

            @include tablet {
                text-align: left;
            }
        }

        .ecoSearch__field--price {

            .ecoSearch__field__input__wrapper:after {
                content: '€';
                color: $color_primary;
                font-weight: 800;
                font-size: 16px;
                display: block;
                width: 8px;
                height: 12px;
                position: absolute;
                right: 20px;
                top: 13px;
            }
            
            .ecoSearch__field__input {
                -webkit-appearance: none;
                -moz-appearance: textfield;
            }
        }

        
    }
}